<template>
	<div>
		
		<div class="Top">
			<span @click="$Jump('/my/favorite/goods_list')">商品收藏</span>
			<span class="On">店铺收藏</span>
		</div>
		
		<div v-if="ShopList.length == 0" style="margin: 20px;padding: 50px 0px;text-align: center;">
			暂无收藏信息
		</div>
		
		<div class="ShopList" v-if="ShopList.length > 0">
			

			<div class="Shop" v-for="item in ShopList" :key="item.Id">
				<div class="Thumb">
					<img v-if="item.ShopLogo && item.ShopLogo.length > 0" :src="item.ShopLogo" />
					<img v-else src="../../../assets/img/goods/thumb_default.png" />
				</div>
				<div class="Info">
					<div class="Title">
						{{item.ShopName}}
					</div>
					<div class="Price">
						<span>
						</span>
						<em>
							<el-button type="primary" round size="mini" @click="$Jump('/shop/'+item.ShopId)">查看店铺</el-button>
							<el-button type="danger" round size="mini" @click="FavoriteDel(item.Id)">删除</el-button>
						</em>
					</div>
				</div>
			</div>

			
			<div class="Shop Empty" v-for="k in (3 - ShopList.length%3)" :key="'ms'+k">
			</div>
			
		</div>
		
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Pagination} from 'element-ui'
	export default {
	  name: 'FavoriteShopList',
	  props: {
	  },
	  data() {
	      return {
			  ShopList:[],
			  Page:1,
			  PageSize:8,
			  Total:0
	      }
	  },
	  components: {
		'el-pagination':Pagination
	  },
	  created() {
	  	this.GetList(1)
	  },
	  methods:{
		  handleCurrentChange(val){
		  			 this.GetList(val)
		  },
		  GetList(_page){

		  		let data = {
		  			Service:'Shop',
		  			Class: 'ShopFavorite',
		  			Action: 'List',
		  			Page:_page,
		  			PageSize:this.PageSize,
		  		}
		  		this.$post(this.$store.getters.getApiHost,data)
		  		.then((res) => {
		  			
		  			if(res.ErrorId != 0){
		  				this.$message(res.Msg)
		  				return
		  			}
					this.ShopList = res.Data.FavoriteList
					this.Page = res.Data.CurrentPage
					this.Total = res.Data.Total
		  			
		  		})
		  		.catch(function (response) {
		  			this.$message('网络请求错误')
		  		})
		  },
		  FavoriteDel(_favId){
		  	let data = {
		  		Service:'Shop',
		  		Class: 'ShopFavorite',
		  		Action: 'Del',
		  		Id:_favId
		  	}
		  	this.$post(this.$store.getters.getApiHost,data)
		  	.then((res) => {
		  		
		  		if(res.ErrorId != 0){
		  			this.$message(res.Msg)
		  			return
		  		}
		  		this.$message('已取消收藏选中店铺')
				this.GetList()
		  	
		  	})
		  	.catch(function (response) {
		  		this.$message('网络请求错误')
		  	})
		  }
	  }
	}
</script>

<style scoped>
	.Top{
		margin: 20px;
		padding: 0px 20px 0px 0px;
		background-color: #FFFFFF;
		display: block;
		height: 40px;
		line-height: 40px;
		border-bottom: 1px solid rgba(255,94,97,1);
	}
	.Top span{
		margin-right: 10px;
		padding: 0px 10px;
		line-height: 40px;
		display: inline-block;
		border-radius: 5px 5px 0px 0px;
		cursor: pointer;
	}
	.Top span.On,.Top span:hover{
		background-color: rgba(255,94,97,1);
		color: #FFFFFF;
	}
.ShopList{
	margin: 20px;
	margin-right: 0px;
	padding-right: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.ShopList .Shop{
	width: 32%;
	margin-bottom: 20px;
	background-color: #FFFFFF;
	display: flex;
}
.ShopList .Empty{
	background-color: rgba(0,0,0,0);
}
.ShopList .Shop .Thumb{
	width: 100px;
	height:100px;
	margin: 20px 20px 20px 20px;
}
.ShopList .Shop .Thumb img{
	width: 100%;
	height: 100%;
}
.ShopList .Shop .Info{
	flex: 1;
	margin: 20px 20px 20px 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.ShopList .Shop .Info .Title{
	width: 100%;
}
.ShopList .Shop .Info .Price{
	display: flex;
	align-items: center;
}
.ShopList .Shop .Info .Price span{
	color: rgba(228,0,0,1);
	font-size: 1.2rem;
}
.ShopList .Shop .Info .Price em{
	flex: 1;
}
</style>
